const serviceApp = process.env.VUE_APP_ADVERTISER_SERVICE || 'ADVERTISER';

export default {
  methods: {
    async listActiveCampaigns() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/campaign/allactive`);
    },

    async listActiveUnrestrictedCampaigns() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/campaign/allactiveunrestricted`);
    },

    async listCampaigns() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign`);
    },

    async listCampaignsByAdvertiser(advertiserId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/advertiser/${advertiserId}`);
    },

    async listCampaignsCategories() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/category`);
    },

    async ListCampaignCommissions(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/comission/list/${campaignId}`);
    },

    async listCampaignsCommissionsByType(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/comission/affiliate/${campaignId}`);
    },

    async listCampaignsBySite(siteId) {
      return this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/list/site/${siteId}`);
    },

    async listCommissionsByCampaign(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/comission/list/${campaignId}`);
    },

    async listCommissionChannels(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/campaign/comissioncanais/${campaignId}`);
    },

    async listCommissionGroupDivisionByCampaign(campaignId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/comission_group_division/list/${campaignId}`
      );
    },

    async searchCampaign(page, perPage, filterCampaign) {
      let queryString;
      if (filterCampaign === undefined || filterCampaign == null || filterCampaign === '') queryString = '';
      else queryString = `${filterCampaign}%26`;

      if (!page) queryString += 'page=1';
      else queryString += page;

      if (!perPage) queryString += '%26perPage=10';
      else queryString += '%26' + perPage;
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/search/v2?${queryString}`);
    },

    async listCampaignRevenues(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/invoice/${campaignId}`);
    },

    async listPaymentPeriod() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/payment_period/list/`);
    },

    async listSiteApprovedInCampaign(campaignId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/approved/list/site/${campaignId}`
      );
    },

    async listSitesInCampaign(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/list/site/${campaignId}`);
    },

    async listSitesCampaignDeeplinkEnabled(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/list/site_active/${campaignId}`);
    },

    async listSiteApprovedDetailsByCampaign(campaignId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/site/approved/list/details/${campaignId}`
      );
    },

    async listSitesDetailsByCampaign(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/site/list/details/${campaignId}`);
    },

    async listCampaignsWithChannelsApproved(channels) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/v2/campaign/bychannels/approved/${channels}`);
    },

    async participateSiteInCampaign(campaignId, siteId) {
      return await this.$http.post(`v2/redirect`, {
        app: serviceApp,
        path: '/api/campaign/site/request',
        params: {
          data: {
            campaign_id: campaignId,
            site_id: siteId
          }
        }
      });
    },

    async relatedSites(campaignId, affiliateId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/site/list/details/${campaignId}/${affiliateId}`
      );
    },

    async createCampaignService(campaignData) {
      return await this.$http.post(`v2/redirect`, {
        app: `${serviceApp}`,
        path: '/api/create/campaign',
        params: {
          data: campaignData
        }
      });
    },

    async getAdvertiserDetails(advertiserId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/advertiser/details/${advertiserId}`);
    },

    async listAdvertisers() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/advertiser`);
    },

    async getComissionGroup(campaignId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/commissioning_group/list/${campaignId}`
      );
    },

    async getGroupByCampaign(campaignId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/schedule_commission/list_groups/${campaignId}`
      );
    },

    async getChannelByComissionGroup(campaignId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/commissioning_group/site/list/${campaignId}`
      );
    },

    async listSiteCampaignsDeeplinkEnabled(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/list/site_active/${campaignId}`);
    },

    async listSiteCampaigns(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/list/site/${campaignId}`);
    },

    async listComissionSchedule(campaignId) {
      return await this.$http.get(
        `v2/redirect?app=${serviceApp}&path=/api/schedule_commission/list_schedules/${campaignId}`
      );
    },

    async getComissionScheduleByID(scheduleId) {
      return await this.$http.get(`v2/redirect?app=${serviceApp}&path=/api/schedule_commission/${scheduleId}`);
    },

    async listChannelsByCommissionGroup(comissionGroupId) {
      return await this.$http.get(
        `v2/redirect?app=${serviceApp}&path=/api/schedule_commission/sites_by_group/${comissionGroupId}`
      );
    },

    async cancelScheduleByID(scheduleId) {
      return await this.$http.post(`v2/redirect?app=${serviceApp}&path=/api/schedule_commission/cancel`, {
        params: {
          data: { id: scheduleId }
        }
      });
    },

    async listSiteApprovedCampaigns(channelId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/approved/affiliate/site/${channelId}`
      );
    },

    async getCampaign(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/${campaignId}`);
    },

    async getCampaignDetails(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/details/${campaignId}`);
    },

    async listCampaign() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign`);
    },

    async listRevenues(campaignId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/invoice/${campaignId}`);
    },

    async updateCustomInvoice(customInvoiceData) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/campaign/save_custom_invoice',
        params: {
          data: customInvoiceData
        }
      });
    },

    async deleteInvoice(customInvoiceId) {
      return await this.$http.delete(
        `v2/redirect?app=${serviceApp}&path=/api/delete/campaign/custom_invoice/${customInvoiceId}`
      );
    },

    async deleteComissionGroupById(comissionGroupId) {
      return await this.$http.delete(
        `v2/redirect?app=${serviceApp}&path=/api/delete/campaign/commissioning_group/${comissionGroupId}`
      );
    },

    async deleteComissionSchedule(scheduleId) {
      return await this.$http.delete(
        `v2/redirect?app=${serviceApp}&path=/api/delete/schedule_commission/${scheduleId}`
      );
    },

    async listCampaignByAdvertiser(advertiserId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/campaign/advertiser/${advertiserId}`);
    },

    async editCampaignDetalhamento(campaignId, campaignData) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/campaign/${campaignId}`,
        params: {
          data: campaignData
        }
      });
    },

    async editCampaign(id, campaignData) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/campaign/deeplink/${id}`,
        params: {
          data: campaignData
        }
      });
    },

    async editCampaignController(
      campaignId,
      advertiserId,
      commercialManagerId,
      operationalManagerId,
      statusCampaign,
      networkManagerId
    ) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/session_controle_campaign/${campaignId}`,
        params: {
          data: {
            advertiser_id: `"${advertiserId}"`,
            comercial_manager_id: `"${commercialManagerId}"`,
            operational_manager_id: `"${operationalManagerId}"`,
            status: `"${statusCampaign}"`,
            network_manager_id: `"${networkManagerId}"`
          }
        }
      });
    },

    async editControlCampaign(campaignId, campaignData) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/session_controle_campaign/${campaignId}`,
        params: {
          data: campaignData
        }
      });
    },

    async listSitesApprovedDetailsByCampaign(campaignId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/site/approved/list/details/${campaignId}`
      );
    },

    async editCommissionGroup(commissionGroup, commissionId) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/campaign/comission/${commissionId}`,
        params: {
          data: commissionGroup
        }
      });
    },

    async editCommissionSchedule(schedule) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/schedule_commission/${schedule.id}`,
        params: {
          data: {
            title: schedule.title,
            comission_group_id: schedule.comission_group_id,
            date: schedule.date,
            site_id_list: schedule.site_id_list
          }
        }
      });
    },

    async deleteSiteFromComissionGroup(campaignId, commissionGroupId, siteId) {
      return await this.$http.put(`/v2/redirect`, {
        app: serviceApp,
        path: `/api/delete/site/commissioning_group/`,
        params: {
          data: {
            campaign_id: campaignId,
            comission_group_id: commissionGroupId,
            site_id: siteId
          }
        }
      });
    },

    async changeComissionGroupNameById(campaignId, commissionGroupId, comissionGroupName) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/commissioning_group/${commissionGroupId}`,
        params: {
          data: {
            campaign_id: campaignId,
            name: comissionGroupName
          }
        }
      });
    },

    async deleteComission(idCommission) {
      return await this.$http.delete(
        `v2/redirect?app=${serviceApp}&path=/api/delete/campaign/comission/${idCommission}`
      );
    },

    async createSiteInComissionGroupById(comissionGroupId, siteId) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/create/site/commissioning_group',
        params: {
          data: {
            comission_group_id: comissionGroupId,
            site_id: siteId
          }
        }
      });
    },

    async createAdvertiser(advertiserData) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/create/advertiser',
        params: {
          data: advertiserData
        }
      });
    },

    async updateAdvertiser(advertiserId, advertiserData) {
      return await this.$http.put(`/v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/advertiser/${advertiserId}`,
        params: {
          data: advertiserData
        }
      });
    },

    async updateAccessEmail(advertiserId, accessEmail) {
      return await this.$http.put(
        //`/v2/redirect?app=PORTEIRA_URL&path=/api/update/user/${advertiserId}`,
        `/update/user/${advertiserId}`,
        {
          email: accessEmail
        }
      );
    },

    async createCommission(commissionGroup) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/create/campaign/comission',
        params: {
          data: commissionGroup
        }
      });
    },

    async editCommissionGroupDivision(groupId, groupPercent, comissionValue) {
      return await this.$http.put(`v2/redirect`, {
        app: serviceApp,
        path: `/api/edit/comission_group_division/${groupId}`,
        params: {
          data: {
            value: groupPercent,
            comission_value: comissionValue
          }
        }
      });
    },

    async createCommissionGroup(campaignId, groupName) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/create/commissioning_group',
        params: {
          data: {
            campaign_id: campaignId,
            name: groupName
          }
        }
      });
    },

    async createCommissionSchedule(scheduleData) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/schedule_commission',
        params: {
          data: scheduleData
        }
      });
    },

    async getSiteByCommissionGroup(campaignId, commissionGroupId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/get/campaign/site/commissioning_group?campaign_id=${campaignId}%26comission_group_id=${commissionGroupId}`
      );
    },

    async getSitesCampaignRelatedByAffiliate(campaignId) {
      return await this.$http.get(
        `/redirect?app=${serviceApp}&path=/api/v2/get/campaign/related/affiliate/${campaignId}`
      );
    },

    async getAllCampaigns() {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/campaign/all`);
    },

    async getCampaignListAffiliate(affiliateId) {
      const path =
        this.user.profile == 'afiliado'
          ? `/api/v2/get/campaign/list/affiliate/${affiliateId}`
          : `/api/get/campaign/list/affiliate/${affiliateId}`;
      return await this.$http.get(`v2/redirect?app=${serviceApp}&path=${path}`);
    },

    async getCampaignHistoric(campaignId) {
      return await this.$http.get(`v2/redirect?app=${serviceApp}&path=/api/logs/campaingChangeLog/${campaignId}`);
    },

    async updateAdvertiserStatus(advID, status) {
      return await this.$http.put(`v2/redirect?app=${serviceApp}&path=/api/advertiser/status/${advID}`, {
        params: {
          data: {
            status
          }
        }
      })
    },

    async updateAdvertiserLevel(advID, level) {
      return await this.$http.put(`v2/redirect?app=${serviceApp}&path=/api/advertiser/level/${advID}`, {
        params: {
          data: {
            level
          }
        }
      })
    }

  }
};
